import { useState, useEffect } from 'react';
// import { useTrackVSMutation } from 'store/api';
import { useLocation } from 'react-router-dom';

export interface Verisoul {
  account: (data: { id?: string; email: string }) => Promise<void>;
  reinitialize: () => Promise<void>;
  session: () => Promise<{
    session_id: string;
  }>;
}

declare const window: Window &
  typeof globalThis & {
    Verisoul?: Verisoul;
  };

function injectVerisoulScript(): void {
  /*
    Verisoul Script. This script is required for Verisoul to work.
    You can also include this tag directly in your index.html file.
    Feel free to use async or defer attributes. Make sure to handle the case where the script is not loaded yet.
    Make sure to include the verisoul-project-id attribute with your project ID.
 */

  if (document.querySelector('script[src*="verisoul.ai"]')) {
    return;
  }

  const script = document.createElement('script');
  script.src = `https://js.verisoul.ai/${process.env.REACT_APP_VERISOUL_ENV}/bundle.js`;
  script.setAttribute(
    'verisoul-project-id',
    process.env.REACT_APP_VERISOUL_PROJECT_ID || ''
  );
  script.defer = true;
  document.body.appendChild(script);
}

function waitForVerisoul(): Promise<Verisoul> {
  const maxRetries = 50; // Maximum retries to avoid infinite loop
  let retryCount = 0;

  return new Promise((resolve, reject) => {
    const checkVerisoul = () => {
      if (window.Verisoul) {
        resolve(window.Verisoul);
        return;
      }

      if (retryCount >= maxRetries) {
        reject(
          new Error('Verisoul script failed to load within the expected time.')
        );
        return;
      }

      // eslint-disable-next-line no-plusplus
      retryCount++;

      requestAnimationFrame(checkVerisoul);
    };

    checkVerisoul();
  });
}

async function fetchVerisoulSessionId(): Promise<string> {
  try {
    const verisoul = await waitForVerisoul();

    const { session_id } = await verisoul.session();

    return session_id || '';
  } catch {
    return '';
  }
}

export async function getVerisoulCurrentSessionId(): Promise<string> {
  return fetchVerisoulSessionId();
}

export async function reInitVerisoul(): Promise<boolean> {
  try {
    const verisoul = await waitForVerisoul();

    await verisoul.reinitialize();

    return true;
  } catch {
    return false;
  }
}

export function useVerisoul(): void {
  const { pathname } = useLocation();

  const [isVerisoulInitialized, setVerisoulInitialized] = useState<boolean>(
    !!window.Verisoul
  );

  // const [mutateTrackVSMutation] = useTrackVSMutation();

  const sendVerisoulSessionId = async () => {
    try {
      const verisoul_session_id = await fetchVerisoulSessionId();
      console.log('verisoul_session_id', verisoul_session_id);

      // const result = await mutateTrackVSMutation({
      //   verisoul_session_id,
      // });

      // if ('error' in result) {
      //   const { error } = result;

      //   if ('status' in error && error.status === 203) {
      //     const reInitSuccess = await reInitVerisoul();

      //     if (reInitSuccess) {
      //       const newVerisoulSessionId = await fetchVerisoulSessionId();

      //       await mutateTrackVSMutation({
      //         verisoul_session_id: newVerisoulSessionId,
      //       });
      //     }
      //   }
      // }
    } catch {
      // No action required in case of error
    }
  };

  // Inject Verisoul script on component mount
  useEffect(() => {
    injectVerisoulScript();
  }, []);

  // Check for Verisoul initialization
  useEffect(() => {
    let isMounted = true;

    const checkForVerisoul = (retries = 50) => {
      if (!isMounted) return;

      if (window.Verisoul) {
        setVerisoulInitialized(true);
      } else if (retries > 0) {
        setTimeout(() => checkForVerisoul(retries - 1), 100);
      }
    };

    checkForVerisoul();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (isVerisoulInitialized) {
      sendVerisoulSessionId().then();
    }
  }, [isVerisoulInitialized, pathname]);
}
